<template>
    <div class="controlroot">
        <div class="title">{{getTitle}}</div>
        <slot></slot>
    </div>
</template>

<script>
export default {
    props:['active'],
    computed:{
        getTitle(){
            switch(this.active){
                case 'banner':
                    return '轮播图'
                    break
                case 'button1':
                    return '按钮组'
                    break
                case 'button2':
                    return '按钮组'
                    break
                case 'button':
                    return '按钮组'
                    break
                case 'movable':
                    return '商户入驻组件'
                    break
                default:
                    return '轮播图'
                    break
            }
        }
    }
}
</script>

<style scoped lang="less">
.controlroot{
    width: 520px;
    min-height: 300px;
    border-radius: 10px;
    box-shadow: 0 2px 12px 0 rgb(200,200,200,0.1);
    padding: 16px;
    .title{
        padding-bottom: 10px;
        border-bottom: 1px solid #eee;
    }
}
</style>
