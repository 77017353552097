import { render, staticRenderFns } from "./Indexset.vue?vue&type=template&id=57db331e&scoped=true&"
import script from "./Indexset.vue?vue&type=script&lang=js&"
export * from "./Indexset.vue?vue&type=script&lang=js&"
import style0 from "./Indexset.vue?vue&type=style&index=0&id=57db331e&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57db331e",
  null
  
)

export default component.exports