<template>
    <div class="movable">
        <!-- 展示 -->
        <img :src="movableinfo.imgURL || 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/620zheng.png'" alt="img" v-if="isShowpart && movableinfo.is_show=='1'" class="movableimg">

        <!-- 控制 -->
        <div class="control"  v-if="!isShowpart">
            <div class="numBox">
                <p>是否显示</p>
                <el-radio-group v-model="movableinfo.is_show">
                    <el-radio label="1">显示</el-radio>
                    <el-radio label="0">不显示</el-radio>
                </el-radio-group>
            </div>
            <div class="numBox" v-show="movableinfo.is_show=='1'">
                <p>入驻图标</p>
                <el-radio-group v-model="movableinfo.is_defaultimg" @change="changeIcon">
                    <el-radio label="1">默认图标</el-radio>
                    <el-radio label="0">上传图片</el-radio>
                </el-radio-group>
            </div>
            <div class="numBox" style="align-items:flex-start;" v-show="movableinfo.is_defaultimg=='0' && movableinfo.is_show=='1'">
                <p>选择图片</p>
                <div class="itemMid">
                    <img :src="movableinfo.imgURL || 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/620zheng.png'" alt="icon" class="imgBox"/>
                    <div class="upload" @click="chooseImg">选择图片</div>
                </div>
                <div class="itemRight">
                    <div></div>
                    <div class="clearImg" @click="clearImg">清除图片</div>
                </div>
            </div>

            <!-- 图片上传弹框 -->
            <UploadSource
            v-if="chooseImgFlag"
            @changeStatus="changeStatus"
            :dialogVisible="chooseImgFlag"
            @getSource="getImgRes"
            ></UploadSource>
        </div>
    </div>
</template>

<script>
import UploadSource from '@/components/uploadSource'
export default {
    props:['movableinfo','isShowpart'],
    components: {
        UploadSource,
    },
    data() {
        return {
            chooseImgFlag: false,
        }
    },
    watch:{
        movableinfo:{
            handler: function(){
                this.updateInfo()
            },
            deep: true
        },
    },
    methods: {
        updateInfo(){
            this.$emit('update-date', {
                attr:this.active,
                data:this.movableinfo
            })
        },
        changeStatus(val) {
            this.chooseImgFlag = val
        },

        // 选择图片
        chooseImg() {
            this.chooseImgFlag = !this.chooseImgFlag
        },
        // 拿到图片
        getImgRes(imglist) {
            this.movableinfo.imgURL = imglist[0].path
        },
        clearImg(){
            this.movableinfo.imgURL = ''
        },

        changeIcon(e){
            if(e=='1'){
                this.movableinfo.imgURL = 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/leituo/forum_settled.png'
            }
        }
    }
}
</script>

<style lang="less" scoped>
.movableimg{
    width: 50px;
    height: 50px;
}
.control {
    font-size: 14px;
    .tips {
        color: #9a9a9a;
    }
    .numBox {
        display: flex;
        align-items: center;
        margin-top: 20px;
        p {
            margin-right: 20px;
        }
    }
    .itemMid {
        position: relative;
        width: 90px;
        height: 90px;
        border: 1px dotted #333;
        margin-right: 10px;
        .imgBox {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            overflow: hidden;
            img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
                object-fit: cover;
            }
        }
        .upload {
            width: 100%;
            position: absolute;
            height: 25px;
            line-height: 25px;
            bottom: 0;
            left: 0;
            color: #e4e4e4;
            cursor: pointer;
            background-color: rgba(0, 0, 0, 0.4);
            font-size: 12px;
            text-align: center;
        }
    }
    .itemRight {
        display: flex;
        align-items: center;
        height: 90px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .clearImg {
            color: #6d99ff;
            cursor: pointer;
            user-select: none;
        }
    }
}
</style>
