<template>
    <div class="buttonroot">
        <!-- 展示 -->
        <div class="buttonbox"  v-if="isShowpart" :style="{backgroundColor:buttoninfo.bgColor}">
            <div v-for="(item,index) in buttoninfo.list" :key="index" :class="[isdesign?'button_item2':'button_item']">
                <img :src="item.imgUrl" alt="img">
                <div>{{item.name}}</div>
            </div>
        </div>
        <!-- 控制 -->
        <div class="control" v-else>
            <div class="numBox" style="margin-top: 20px;">
                <p>每行数量</p>
                <el-radio-group @change="changeLayout" v-model="buttoninfo.layout">
                    <el-radio label="3">3个</el-radio>
                    <el-radio label="4">4个</el-radio>
                    <el-radio label="5">5个</el-radio>
                </el-radio-group>
            </div>
            <div class="bgColor">
                <p>背景颜色</p>
                <el-color-picker v-model="buttoninfo.bgColor"></el-color-picker>
                <el-button type="primary" @click="returnColor">重置</el-button>
            </div>
            <div class="bgColor">
                <p></p>
                <div class="tips">建议图片尺寸96px*96px</div>
            </div>
            <div class="listbox">
                <vuedraggable
                    v-model="buttoninfo.list"
                    v-bind="{
                    animation: 200,
                    group: 'description',
                    disabled: false,
                    ghostClass: 'ghost',
                    scroll: true
                    }"
                >
                    <transition-group>
                        <div class="btnGroup" v-for="(item, index) in buttoninfo.list" :key="index">
                            <div class="itemLeft">
                            <span @click="moveClick(0, index, item)" :class="index == 0 ? 'fcccc' : ''"
                                >&lt;</span
                            >
                            <span
                                @click="moveClick(1, index, item)"
                                :class="index == buttoninfo.list.length - 1 ? 'fcccc' : ''"
                                >></span
                            >
                            </div>
                            <div class="itemMid">
                            <div class="imgBox">
                                <img v-if="item.imgUrl" :src="item.imgUrl" alt="" />
                            </div>
                            <div class="upload" @click="chooseImg(index)">选择图片</div>
                            </div>
                            <div class="itemRight">
                            <div style="margin-right: 10px">
                                <div>
                                <span>输入文字</span>
                                <el-input v-model="item.name"></el-input>
                                </div>
                                <div>
                                <el-input v-model="item.toPage" disabled></el-input>
                                <el-button type="primary" @click="toPageShow(index)">选择</el-button>
                                </div>
                            </div>
                            <div @click="deleteItem(index)" style="justify-content: center">
                                <i :class="['iconfont', index != 0 ? 'icon-guanbi' : '']"></i>
                            </div>
                            </div>
                        </div>
                    </transition-group>
                </vuedraggable>
            </div>
            <div @click="addBtn" class="addOne">新增一个</div>

            <!-- 选择跳转页面弹框 -->
            <el-dialog title="选择链接" :visible.sync="toPageFlag" width="52%">
                <div>
                    <ToPage ref="toPage" @getPage="getPageRes"></ToPage>
                </div>
            </el-dialog>

            <!-- 图片上传弹框 -->
            <UploadSource
            v-if="chooseImgFlag"
            @changeStatus="changeStatus"
            :dialogVisible="chooseImgFlag"
            @getSource="getImgRes"
            ></UploadSource>
        </div>
    </div>
</template>

<script>
import vuedraggable from 'vuedraggable'
import UploadSource from '@/components/uploadSource'
import ToPage from '@/views/addPages/toPage/index'
export default {
    props:['buttoninfo','isShowpart','isdesign'],
    components: {
        vuedraggable,
        UploadSource,
        ToPage
    },
    data() {
        return {
            toPageFlag: false,
            itemIndex: -1,
            chooseImgFlag: false,
            checkLayout: null
        }
    },
    watch:{
        buttoninfo:{
            handler: function(){
                this.updateInfo()
            },
            deep: true
        },
    },
    methods: {
        updateInfo(){
            this.$emit('update-date', {
                attr:this.active,
                data:this.buttoninfo
            })
        },
        changeStatus(val) {
            this.chooseImgFlag = val
        },
        // 重置颜色
        returnColor() {
            this.buttoninfo.bgColor = null
        },
        // 点击移动banner图位置
        moveClick(status, index, item) {
            if (status == 0) {
                // 向上移
                if (index == 0) {
                this.$message({
                    message: '已经在最前面了~',
                    type: 'error'
                })
                return
                }
                this.buttoninfo.list.splice(index, 1)
                this.buttoninfo.list.splice(index - 1, 0, item)
            } else {
                // 向下移
                if (index == this.buttoninfo.list.length - 1) {
                this.$message({
                    message: '已经在最后面了~',
                    type: 'error'
                })
                return
                }
                this.buttoninfo.list.splice(index + 2, 0, item)
                this.buttoninfo.list.splice(index, 1)
            }
        },
        // 删除按钮
        deleteItem(index) {
            if (this.buttoninfo.list.length == 2) {
                this.$message({
                message: '按钮数量不可小于2个',
                type: 'warning'
                })
                return
            }
            this.buttoninfo.list.splice(index, 1)
        },
        // 开启选择链接弹框
        toPageShow(index) {
            this.toPageFlag = true
            this.itemIndex = index
            this.$nextTick(() => {
                this.$refs.toPage.getPageArr()
            })
        },
        // 拿到跳转路径
        getPageRes(item) {
            this.buttoninfo.list[this.itemIndex].toPage = item.wxroute
            this.toPageFlag = false
        },
        // 选择图片
        chooseImg(index) {
            this.itemIndex = index
            this.chooseImgFlag = !this.chooseImgFlag
        },
        // 添加按钮组
        addBtn() {
            if (this.buttoninfo.layout == this.buttoninfo.list.length) {
                this.$message({
                message: '按钮数量不可大于所勾选的每行数量',
                type: 'warning'
                })
                return
            }
            this.buttoninfo.list.push({
                name: '按钮文字',
                imgUrl: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/zheng.png',
                toPage: '跳转页面'
            })
        },
        // 拿到图片
        getImgRes(imglist) {
            this.buttoninfo.list[this.itemIndex].imgUrl = imglist[0].path
        },
        changeLayout(index) {
            if (index < this.buttoninfo.list.length) {
                this.$message({
                    message: '每行数量不可小于按钮数量',
                    type: 'warning'
                })
                this.buttoninfo.layout = this.checkLayout
            }else{
                this.checkLayout = index
            }
            if(index>this.buttoninfo.list.length){
                for(let i=1;i<index - this.buttoninfo.list.length+1;i++){
                    this.buttoninfo.list.push({
                        name: '按钮文字',
                        imgUrl: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/zheng.png',
                        toPage: '跳转页面'
                    })
                }
            }
        }
    }
}
</script>

<style lang="less" scoped>
.buttonbox{
    display: flex;
    padding: 6px 0;
    align-items: center;
    .button_item{
        flex:1;
        display: flex;
        flex-direction: column;
        align-items: center;
        img{
            width: 50px;
            height: 50px;
            object-fit: cover;
            margin-bottom: 4px;
            border-radius: 50%;
        }
        >div{
            font-size: 12px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #000000;
            line-height: 20px;
        }
    }
    .button_item2{
        flex:1;
        display: flex;
        flex-direction: column;
        align-items: center;
        img{
            width: 36px;
            height: 28px;
            object-fit: cover;
            margin-bottom: 12px;
        }
        >div{
           font-size: 14px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #222222;
        }
    }
}

.control {
    font-size: 14px;
    .tips {
        color: #9a9a9a;
    }
    .numBox {
        display: flex;
        align-items: center;
        p {
            margin-right: 20px;
        }
    }
    .bgColor {
        display: flex;
        align-items: center;
        margin: 16px 0;
        p {
            margin-right: 20px;
        }
        .el-color-picker/deep/.el-color-picker__trigger {
            width: 150px;
        }
    }
    .btnGroup {
        margin: 16px 0;
        display: flex;
        align-items: center;
        .itemLeft {
            width: 30px;
            height: 100%;
            span {
                width: 16px;
                height: 16px;
                line-height: 16px;
                text-align: center;
                border-radius: 5px;
                display: block;
                border: 1px solid #333;
                font-weight: bold;
                cursor: pointer;
            }
            span:first-child {
                transform: rotate(90deg);
            }
            span:last-child {
                transform: rotate(90deg);
                margin-top: 10px;
            }
        }
        .itemMid {
            position: relative;
            width: 90px;
            height: 90px;
            border: 1px dotted #333;
            margin-right: 10px;
            .imgBox {
                width: 100%;
                height: 100%;
                border-radius: 50%;
                overflow: hidden;
                img {
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                    object-fit: cover;
                }
            }
            .upload {
                width: 100%;
                position: absolute;
                height: 25px;
                line-height: 25px;
                bottom: 0;
                left: 0;
                color: #e4e4e4;
                cursor: pointer;
                background-color: rgba(0, 0, 0, 0.4);
                font-size: 12px;
                text-align: center;
            }
        }
        .itemRight {
            display: flex;
            align-items: center;
            & > div {
                height: 90px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                & > div {
                    display: flex;
                    span {
                        width: 120px;
                        line-height: 40px;
                    }
                }
            }
        }
    }
    .addOne {
        width: 100%;
        height: 40px;
        text-align: center;
        line-height: 40px;
        color: #3c76ff;
        border: 1px solid #3c76ff;
        cursor: pointer;
        margin-top: 20px;
    }
}
.fcccc {
  color: #ccc;
  border: 1px solid #ccc !important;
  cursor: no-drop !important;
}
</style>
